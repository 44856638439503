<template>
  <router-view :key="$route.path" />
</template>

<script>
export default {
  components: {},
  setup() {},
};
</script>



